@import-normalize;

body {
  margin:0px;
  padding:0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mybrand {
  text-align: center;
  font-size: xx-large;
  color: white;
}

a.mybrand:hover {
  text-decoration: none!important;
  color: white!important;
}

.mynavitem {
  text-align: left;
  color: white;
}

a.mynavitem:hover {
  text-decoration: none!important;
  color: white!important;
}

.mycontact {
  color: black;
}

a.mycontact:hover {
  text-decoration: none!important;
  color: black!important;
}

.navbar {
  padding-left: 1.0rem;
  padding-right: 1.0rem;
}

.left {
  text-align: left;
  margin: 20px;
}

.right {
  float: right;
  text-align: right;
}

.center {
  text-align: center;
}

html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: 60px; /* Margin bottom by footer height */
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}
